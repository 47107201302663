import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// Services
import { BroadcastService } from '../../../services/broadcast.service';
import { PermissionsService } from '../../../services/permissions.service';
import { TicketsService } from '../../../services/tickets.service';
import { ticketsTableOptions } from '../../../constants/tickets';
import { environment } from '../../../../environments/environment';
import { SwalService } from '../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../components/modal/modal.component';
import { UploadPhotosComponent } from '../../../components/shared/upload-photos/upload-photos.component';
import { SharedComponent } from '../../../model/shared-component';
import { AssignComponent } from './assign/assign.component';
import { AssignationCreateEditComponent } from './assignation-create-edit/assignation-create-edit.component';
import { CloseComponent } from './close/close.component';
import { RecordComponent } from './record/record.component';
import { UnrealizedComponent } from './unrealized/unrealized.component';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})

export class TicketsComponent implements OnDestroy, OnInit {
  @Input('type') type?: string;
  broadcast$: Subscription;
  subscriptions: Array<Subscription> = [];
  notSupportedEvents = environment.notSupportedEvents;
  dataTableConfig: any;
  ticketTableOptions = ticketsTableOptions;
  private readonly baseDataTableConfig = {
    config: {
      type: 'tickets',
      base: this.ticketsSevice,
      api: 'getTickets',
      role: 3,
      order: [[0, 'desc']]
    },
    columns: [
      {
        display: 'Estatus',
        field: 'id_tickets',
        type: 'ticket_status'
      },
      {
        display: 'Técnico',
        field: 'assignations',
        type: 'ticket_technician'
      },
      {
        display: 'CLIENTE',
        field: 'client.name',
        type: 'ticket_client',
        orderable: false
      },
      {
        display: 'CONTACTO',
        field: 'client',
        type: 'ticket_contact',
        orderable: false
      },
      {
        display: 'FECHA DE CREACION',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: this.ticketTableOptions
      }
    ],
    filters: [
      {
        cssClass: 'col-md-12',
        type: 'status',
        options: [
          {
            val: '',
            name: 'TODOS'
          },
          {
            val: 'opened',
            name: 'ABIERTO'
          },
          {
            val: 'assigned',
            name: 'ASIGNADO'
          },
          {
            val: 'completed',
            name: 'COMPLETADO POR EL TECNICO'
          },
          {
            val: 'confirmed',
            name: 'FIRMADO POR EL CLIENTE'
          },
          {
            val: 'closed',
            name: 'CERRADO'
          },
          {
            val: 'unrealized',
            name: 'NO REALIZADO'
          }
        ]
      }
    ]
  };

  constructor(
    public appModal: ModalComponent,
    public activeModal: NgbActiveModal,
    private readonly swalService: SwalService,
    private readonly ticketsSevice: TicketsService,
    private readonly broadcast: BroadcastService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.setView();
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        if (this[event.name]) {
          this[event.name](event.data);
        }
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
     * editTicketModal
     * Abre un modal para editar la fecha - hora de realizacion de un ticket o la
     * descripción sin necesidad de reasignar el ticket
     * @param data informacion del ticket
     */
  editTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignationCreateEditComponent,
      { ticket: data, status: 'edit' },
      { title: `Edición del ticket #${data.id_tickets}` }
    );
    this.appModal.opensm(props);
  }

  /**
   * assignTicketModal
   * Abre un modal para assignar un ticket a un tecnico
   * @param data 
   */
  assignTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignComponent,
      { ticket: data, status: 'assign' },
      { title: `Asignación de ticket ${data.id_tickets}` }
    );
    this.appModal.openXl(props);
  }

  /**
   * reassignTicket Modal
   * Abre un modal para reasignar el ticket a otro tecnico
   * @param data 
   */
  reassingTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignComponent,
      { ticket: data, status: 'reassing' },
      { title: `Reasignación de ticket #${data.id_tickets}` }
    );
    this.appModal.openXl(props);
  }

  /**
   * unassignTicketModal
   * Abre un modal para marcar como desaasignado un ticket
   * @param data informacion del ticket
   */
  unassignTicketModal(data): void {
    const swalParams = {
      title: '¿Desea desasignar este ticket?',
      text: '¡Si desasigna este ticket el estatus pasara a abierto!'
    };

    this.swalService.warning(swalParams).then(result => {
      if (result.value) {
        const params = {
          status: 'unassigned'
        };
        this.ticketsSevice.setStatus(params, data.id_tickets).subscribe((res: any) => {
          if (res.success) {
            this.swalService.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error({ title: 'Ocurrió un error al actualizar los datos' });
          }
        });
      }
    });
  }

  /**
   * closeTicketModal
   * Abre un modal para ingresar los datos de cerrado del ticket por parte del staff
   * @param data informacion del ticket
   */
  closeTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(CloseComponent, data, { title: `Cerrar el ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.opensm(props);
  }

  /**
    * unrealizedTicketModal
    * Manda una peticion a la API para marcar un ticket como no realizado.
    * @param data  información del ticket
    */
  unrealizedTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      UnrealizedComponent,
      data,
      { title: `Marcar como no realizado el ticket ${data.id_tickets} del cliente ${data.client.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
    * uploadPhotosTicketModal
    * Abre un modal para subir imagenes que se asociaran al ticket
    * @param data 
    */
  uploadPhotosTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      UploadPhotosComponent,
      data,
      { title: `Subir imagenes al ticket ${data.id_tickets} del cliente ${data.client.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
   * recordModal
   * Abre un modal para visualizar el historial de un ticket
   * @param data informacion del ticket 
   */
  recordModal(data): void {
    const props: SharedComponent = new SharedComponent(RecordComponent, data, { title: `Historial del ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.openXl(props);
  }

  /**
   * updateIncompleteDataFlagModal
   * Manda una peticion put a la api para setear el campo incomplete_data a true
   * @param ticket informacion del ticket
   */
  updateIncompleteDataFlagModal(ticket): void {
    const idTicket = ticket.id_tickets;
    const swalParams = {
      title: `¿Desea remover la restricción de las imagenes para el ticket ${idTicket}?`,
      text: 'Se removera la restricción de las imagenes, favor de proporcionar la razón',
      inputPlaceholder: 'Razones',
      confirmButtonText: 'Actualizar'
    };

    this.swalService.input(swalParams).then((resp: any) => {
      if (resp.value) {
        this.subscriptions.push(this.ticketsSevice.updateIncompleteDataFlag(idTicket, { reasons: resp.value }).subscribe((response: any) => {
          if (response.success) {
            this.swalService.success({ text: 'Desbloqueo realizado exitosamente' }).then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error();
          }
        }));
      }
    });
  }

  /**
   * setView
   * Configura baseDataTableConfig dependiendo del input ingreasado
   */
  private setView(): void {
    if (this.type && this.type === 'opened') {
      const params = { params: { status: 'opened' } };
      Object.assign(this.baseDataTableConfig.config, params);
      this.baseDataTableConfig.filters = [];
    }
  }
}
