import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'history-ticket-closed',
  templateUrl: './closed.component.html',
  styleUrls: ['./closed.component.scss']
})
export class ClosedComponent implements OnInit {
  @Input('item') item: any;
  incompleteData = false;
  ngOnInit(): void {
    this.incompleteData = this.item.incomplete_data === '1' && this.item.incomplete_data_reasons;
  }

}
