import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TicketsService = /** @class */ (function () {
    function TicketsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    TicketsService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/tickets", params);
    };
    TicketsService.prototype.update = function (id_tickets, params) {
        return this.http.put(this.baseUrl + "/ticket/" + id_tickets, params);
    };
    TicketsService.prototype.getTickets = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/tickets/get_tickets", params);
    };
    TicketsService.prototype.getTechnicians = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/users/Technician", params);
    };
    TicketsService.prototype.getErrorCodes = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.get(this.baseUrl + "/error-codes/get_error_codes", params);
    };
    TicketsService.prototype.getHistory = function (id_tickets) {
        var params = {
            params: { id_tickets: id_tickets }
        };
        var assignations = this.http.get(this.baseUrl + "/assignations", params);
        var completions = this.http.get(this.baseUrl + "/completions", params);
        var confirmations = this.http.get(this.baseUrl + "/confirmations", params);
        var unrealizations = this.http.get(this.baseUrl + "/unrealizations", params);
        return forkJoin([assignations, completions, confirmations, unrealizations]);
    };
    TicketsService.prototype.getTicketTypes = function () {
        return this.http.get(this.baseUrl + "/tickets/get_ticket_type");
    };
    TicketsService.prototype.getMapTickets = function (params) {
        return this.http.post(this.baseUrl + "/tickets/get_map_tickets", params);
    };
    TicketsService.prototype.setStatus = function (params, id) {
        return this.http.post(this.baseUrl + "/tickets/" + id + "/setStatus", params);
    };
    TicketsService.prototype.getPhotos = function (ticketId) {
        return this.http.get(this.baseUrl + "/tickets/" + ticketId + "/photos");
    };
    TicketsService.prototype.addPhotos = function (params, ticketId) {
        return this.http.post(this.baseUrl + "/tickets/" + ticketId + "/addPhotos", params);
    };
    TicketsService.prototype.simTicketsNextMonth = function (params) {
        return this.http.post(this.baseUrl + "/tickets/simTicketsNextMonth", params);
    };
    TicketsService.prototype.getInstallationsImages = function (id_clients) {
        return this.http.get(this.baseUrl + "/tickets/" + id_clients + "/getInstallationsImages");
    };
    TicketsService.prototype.openTickets = function () {
        return this.http.get(this.baseUrl + "/tickets/openTickets");
    };
    TicketsService.prototype.updateIncompleteDataFlag = function (id_tickets, params) {
        return this.http.put(this.baseUrl + "/ticket/updateIncompleteDataFlag/" + id_tickets, params);
    };
    TicketsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketsService_Factory() { return new TicketsService(i0.ɵɵinject(i1.HttpClient)); }, token: TicketsService, providedIn: "root" });
    return TicketsService;
}());
export { TicketsService };
