<div class="col-md-12 content">
    <history-ticket-badge [item]="item"></history-ticket-badge>
    <dl class="row">
        <dt class="col-md-4">Costo final</dt>
        <dd class="col-md-8">{{ item.final_service_fee | currency }}</dd>
        <dt class="col-md-4">Razones de costo</dt>
        <dd class="col-md-8">{{ item.final_service_fee_reasons || 'Sin cargos extra' }}</dd>
        <dt class="col-md-4" *ngIf="incompleteData">Razón de desbloqueo de imaggenes </dt>
        <dd class="col-md-8" *ngIf="incompleteData">{{ item.incomplete_data_reasons }}</dd>
    </dl>
</div>