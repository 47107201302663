<h5 class="font-italic">Información del ultimo ticket</h5>
<br>
<dl class="row">
    <dt class="col-sm-3">Ticket ID</dt>
    <dd class="col-sm-9">{{ticketData.id_tickets}}</dd>
    <dt class="col-sm-3">Código de error</dt>
    <dd class="col-sm-9">{{ticketData.error_code.code}} - {{ticketData.error_code.name}}</dd>
    <dt class="col-sm-3">Status</dt>
    <dd class="col-sm-9">{{ticketData.status}}</dd>
    <dt class="col-sm-3" *ngIf="ticketData.assignations[0]">Técnico</dt>
    <dd class="col-sm-9" *ngIf="ticketData.assignations[0]">
        {{ticketData.assignations[0]?.technician.name}}
    </dd>
    <dt class="col-sm-3" *ngIf="ticketData.assignations[0]?.completion">
        Comentarios del técnico
    </dt>
    <dd class="col-sm-9" *ngIf="ticketData.assignations[0]?.completion">
        {{ticketData.assignations[0].completion.work_description}}
    </dd>

    <dt class="col-sm-3" *ngIf="ticketData.assignations[0]?.completion">
        Fecha de cierre del ticket por parte del técnico
    </dt>
    <dd class="col-sm-9" *ngIf="ticketData.assignations[0]?.completion">
        {{ticketData.assignations[0].completion.created_at}}
    </dd>
    <dt class="col-sm-3">Fotografías del técnico</dt>
    <dd class="col-sm-9" *ngIf="images && images.length > 0; else imagesNotfound"><a href="#" (click)="showImagesBox($event)">Ver fotos...</a></dd>
</dl>

<ng-template #imagesNotfound>Fotografías del técnico no encontradas</ng-template>
