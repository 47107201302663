import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TicketsService } from '../../../services/tickets.service';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-previous-ticket-data',
  templateUrl: './previous-ticket-data.component.html',
  styleUrls: ['./previous-ticket-data.component.scss']
})
export class PreviousTicketDataComponent implements OnInit, OnDestroy {
  @Input('ticketData') ticketData: any;
  private readonly baseUrl = `${environment.apiUrl}`;
  images = [];
  constructor(
    private readonly lightBox: Lightbox,
    private readonly domSanitizer: DomSanitizer,
    private readonly ticketsService: TicketsService
  ) {}

  ngOnInit(): void {
    this.getPhotos();
  }

  ngOnDestroy(): void {
      
  }

  private getPhotos(): void {
    const ticketId = this.ticketData.id_tickets;
    this.ticketsService.getPhotos(ticketId).subscribe((files: Array<string>) => {
      this.images = files.map(file =>
      ({
        src: this.domSanitizer.bypassSecurityTrustUrl(`${this.baseUrl}/documents/tickets/${ticketId}/${file}`),
        caption: `Imagen ${file}`
      }));
    });
  }

  showImagesBox(event?): void {
    if (event) {
      event.preventDefault();
    }
    this.lightBox.open(this.images);
  }

  closeImageBox(): void {
    this.lightBox.close();
  }

}
