export const ticketActionsIcons = {
    'edit': 'fa fa-pencil',
    'assign': 'fa fa-hand-o-right',
    'unassign': 'fa fa-hand-o-left',
    'images': 'fa fa-picture-o',
    'history': 'fa fa-search'
}

export const ticketsTableOptions = [
    {
        display: 'Historial',
        icon: 'fa fa-search',
        event: 'recordModal',
        conditionality: 'true'
    },
    {
        display: 'Asignar ticket',
        icon: 'fa fa-hand-o-right',
        event: 'assignTicketModal',
        conditionality: 'this.data.status === "opened"',
        permissions: ['update-tickets']
    },
    {
        display: 'Reasignar ticket',
        icon: 'fa fa-hand-o-right',
        event: 'reassingTicketModal',
        conditionality: 'this.data.status === "assigned"',
        permissions: ['update-tickets']
    },
    {
        display: 'Desasignar ticket',
        icon: 'fa fa-hand-o-left',
        event: 'unassignTicketModal',
        conditionality: 'this.data.status === "assigned"',
        permissions: ['update-tickets']
    },
    {
        display: 'Editar descripción',
        icon: 'fa fa-pencil',
        event: 'editTicketModal',
        conditionality: 'this.data.status === "assigned"',
        permissions: ['update-tickets']
    },
    {
        display: 'Remover restricción de imagenes',
        icon: 'fa fa-unlock',
        event: 'updateIncompleteDataFlagModal',
        conditionality: 'this.data.status === "assigned" && this.data.incomplete_data === 0',
        permissions: ['update-tickets']
    },
    {
        display: 'Ticket no realizado',
        event: 'unrealizedTicketModal',
        conditionality: '["opened", "assigned"].includes(this.data.status)',
        permissions: ['update-tickets']
    },
    {
        display: 'Cerrar ticket',
        event: 'closeTicketModal',
        conditionality: '["completed", "confirmed"].includes(this.data.status)',
        permissions: ['update-tickets']
    },
    {
        display: 'Subir imagenes',
        icon: 'fa fa-picture-o',
        event: 'uploadPhotosTicketModal',
        conditionality: 'this.data.add_images ',
        permissions: ['update-tickets']
    }
];

export const ticketsTypes = {
    'installation': 'Instalación',
    'service_call': 'Llamada de soporte',
    'filter_change': 'Cambio de filtro',
    'removal': 'Desinstalación',
    'move': 'Cambio de domicilio del cliente'
}