<div class="card-deck mb-3">
    <div class="card bg-{{data.bg}} text-white">
        <div class="card-body">
            <h5 class="card-title d-inline p-2">{{data?.title}}</h5>
            <p class="card-text d-inline p-2">{{data?.total}}</p>
        </div>
        <div *ngIf="data?.footerText" class="card-footer">
            <small class="text-muted">{{data?.footerText}}</small>
        </div>
    </div>
</div>